import logo from "../images/StBedes-logo-100-colour.png";

function Disclaimer() {
  return (
    <div className="cover-screen" id="cover-screen">
      <div id="disclaimer">
        <h2>Disclaimer</h2>
        <img
          style={{
            position: "absolute",
            height: "100px",
            opacity: "1",
            top: "10px",
            right: "30px",
          }}
          src={logo}
          alt="St. Bede's Anglican Church"
        />
        <div id="disclaimer-body">
          <br />
          Welcome to our website! <br />
          <br />
          <p>
            <strong>We use cookies.</strong> We use cookies to collect
            information about you. We use this information to give you a better
            experience of our website. Click “OK” to give us your consent to use
            cookies. <br />
            <br />
          </p>
          Please take a moment to read this important disclaimer before using or
          interacting with the content provided herein. By accessing and using
          this website, you agree to the terms and conditions outlined in this
          disclaimer. If you do not agree with these terms, please refrain from
          using this website. <br />
          <ul style={{ margin: "5px", padding: "10px" }}>
            <li>
              <strong>Accuracy and Completeness:</strong> The information
              presented on this website is for general informational purposes
              only. While we strive to provide accurate and up-to-date
              information, we make no guarantees about the accuracy,
              reliability, or completeness of the content. Any reliance you
              place on the information provided is at your own risk.
            </li>
            <li>
              <strong>Professional Advice: </strong>The content on this website
              is not intended to substitute professional advice in any field,
              including but not limited to medical, legal, financial, or
              technical matters. Always seek the advice of a qualified
              professional for specific concerns related to your situation.
            </li>
            <li>
              <strong> Third-Party Links:</strong> Our website may contain links
              to third-party websites. These links are provided for your
              convenience and do not imply endorsement or approval of the
              content, policies, or practices of these websites. We have no
              control over the content or availability of external sites and are
              not responsible for any information, products, or services offered
              by them.
            </li>
            <li>
              <strong>User Responsibility:</strong> You are solely responsible
              for how you use the information and services provided on this
              website. We cannot be held liable for any actions you take based
              on the content of this website.
            </li>
            <li>
              <strong>Security and Viruses:</strong> We make reasonable efforts
              to ensure the security of our website. However, we do not
              guarantee that the website is free of viruses or other harmful
              components. It is your responsibility to use appropriate measures
              to protect your devices and data.
            </li>
            <li>
              <strong>Changes to the Disclaimer:</strong> We reserve the right
              to modify or update this disclaimer at any time without prior
              notice. It is your responsibility to review this disclaimer
              periodically to stay informed of any changes. By continuing to use
              this website, you acknowledge that you have read, understood, and
              agree to abide by the terms and conditions outlined in this
              disclaimer. If you have any questions or concerns regarding this
              disclaimer, please contact us.
            </li>
          </ul>
        </div>
        <br />

        <button
          className="btn btn-primary"
          onClick={() => {
            document.getElementById("cover-screen").hidden = true;

            localStorage.setItem("disclaimer", "accepted");
          }}
        >
          Ok
        </button>
      </div>
    </div>
  );
}

export default Disclaimer;
