import React, { Component } from "react";
import Card from "react-bootstrap/Card";

import "../css/App.css";
import isikha from "../images/priests/irwin-sikha.jpeg";
import data from "../data/page-content.json";
import Footer from "./Footer";
import { Columns, ViewList } from "react-bootstrap-icons";

class About extends Component {
  constructor(props) {
    super(props);
    console.log("About", props);
  }

  render() {
    let history = data.history;
    let priests = data.priests;

    return (
      <div>
        <div id="our-incumbent" className="card-container-90">
          <h1 className="m-5">Our Incumbent</h1>
          <div className="d-flex flex-row card-container-90">
            <div>
              <img
                src={isikha}
                alt="irwin-sikha"
                style={{ width: "50%" }}
              ></img>
              <div>Rev. Dr. Irwin Sikha</div>
            </div>
            <p className="w-50">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Diam
              sit amet nisl suscipit adipiscing bibendum est. Ultrices vitae
              auctor eu augue ut lectus. Id venenatis a condimentum vitae sapien
              pellentesque habitant morbi. Massa tincidunt dui ut ornare lectus.
              Nunc aliquet bibendum enim facilisis gravida neque. Consequat
              semper viverra nam libero justo laoreet sit amet cursus. Pharetra
              vel turpis nunc eget lorem. Mattis nunc sed blandit libero. Lacus
              vel facilisis volutpat est velit egestas dui id.
            </p>
          </div>
        </div>

        <div id="our-priests" className="card-container-90 ">
          <h1 className="m-5">Previous priests</h1>
          <div
            id="change-view"
            className="card-container-90 d-flex flex-row justify-content-end"
          >
            <Columns
              className="m-1 fs-5"
              onClick={() =>
                (document.getElementById("our-priests-list").style.flexWrap =
                  "wrap")
              }
            />
            <ViewList
              className="m-1 fs-5"
              onClick={() =>
                (document.getElementById("our-priests-list").style.flexWrap =
                  "nowrap")
              }
            />
          </div>
          <div
            className="outer"
            id="our-priests-list"
            style={{ flexWrap: "wrap" }}
          >
            {priests.map((p, index) => {
              return (
                <div className="m-2">
                  <img
                    src={p.image}
                    alt={p.name}
                    // className="w-50"
                    style={{
                      width: "200px",
                      height: "250px",
                      objectFit: "cover",
                    }}
                  ></img>
                  <div>
                    <p
                      style={{
                        fontWeight: "bolder",
                        backgroundColor: "lightgray",
                        padding: "10px",
                      }}
                    >
                      {p.name}
                    </p>
                    <p
                      id="about-priest"
                      style={{ width: "200px", textAlign: "left" }}
                    >
                      {priests.length - index}. {p.about}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div id="history-card-container" className="card-container-90 ">
          <Card id="history-card-index" style={{ border: "none" }}>
            <h1 className="m-5">Our Journey</h1>
            {/* <div className="our-journey-video">
              <iframe
                width="40%"
                // height="50%"
                src="https://www.youtube.com/embed/5IRKML0oEp0"
                title="St. Bede the beginings"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{
                  objectFit: "fill",
                  height: "350px",
                  padding: "5px",
                }}
              ></iframe>

              <iframe
                width="40%"
                // height="50%"
                src="https://www.youtube.com/embed/P2WfV8qUXto"
                title="St. Bede in the new millennium"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                style={{
                  objectFit: "fill",
                  height: "350px",
                  padding: "5px",
                }}
              ></iframe>
            </div> */}
            <Card.Body className="d-flex flex-row justify-content-evenly align-items-centers">
              <div className="w-75">
                {history.map((eachCard, index) => {
                  return (
                    <div key={"history-" + index}>
                      <Card.Img variant="top" />
                      <Card.Title style={{ textAlign: "left" }}>
                        <h4 className="history-year">
                          {Object.keys(eachCard)}
                        </h4>
                      </Card.Title>

                      <Card.Text className="history-text">
                        {Object.values(eachCard)}
                      </Card.Text>
                    </div>
                  );
                })}
              </div>
              {/* <div
                id="history-img-container"
                style={{ backgroundColor: "white", width: "50%" }}
              >
                <div className=" d-flex justify-content-grid flex-wrap">
                  {data.pictures.map((pic, index) => {
                    if (index <= 10) {
                      return (
                        <img
                          key={"img-" + index}
                          style={{
                            // width: Math.random(2) * 100 + "%",
                            width: "40%",
                            borderRadius: "10%",
                            margin: "5px",
                            objectFit: "cover",
                          }}
                          src={pic}
                          alt="pic"
                        />
                      );
                    }
                  })}
                </div>
              </div> */}
            </Card.Body>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
