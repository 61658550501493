import { createApi } from "unsplash-js";
var listofimages = [];

const unsplash = createApi({
  accessKey: "DLj2mHOxtD8X4Oy1J9JagqG6rEq8cMQWlttbAshu-iE",
  // `fetch` options to be sent with every request
  // headers: { "X-Custom-Header": "foo" },
});

// const PhotoComp = ({ photo }) => {
// 	const { user, urls } = photo;

// 	return (
// 		<Fragment>
// 			<img className="img" src={urls.regular} />
// 			<a
// 				className="credit"
// 				target="_blank"
// 				href={`https://unsplash.com/@${user.username}`}
// 			>
// 				{user.name}
// 			</a>
// 		</Fragment>
// 	);
// };

// unsplash.search
// 	.getPhotos({ query: "books", orientation: "landscape" })
// 	.then((result) => {
// 		// setPhotosResponse(result);
// 		listofimages = result;

// 		let imgs = [...document.querySelectorAll(".card-img-top")];
// 		imgs.map((i, index) => {
// 			i.src = listofimages.response.results[index].urls.thumb;
// 		});
// 	})
// 	.catch(() => {
// 		console.log("something went wrong!");
// 	});

// unsplash.photos.get(
// 	// { photoId: "123" },
// 	// `fetch` options to be sent only with _this_ request
// 	{ headers: { "X-Custom-Header-2": "bar" } }
// );
changeImageThemes();

function changeImageThemes(theme = "sun", element) {
  theme = theme === "" ? "sun" : theme;

  if (element === undefined) {
    return;
  }

  unsplash.search
    .getPhotos({ query: theme, orientation: "landscape" })
    .then((result) => {
      // setPhotosResponse(result);

      listofimages = result;
      console.log("listofimages", listofimages);
      return listofimages;

      //   let imgs = [...element.querySelectorAll(".card-img-top")];
      //   let ii = 0;
      //   imgs.map((i, index) => {
      //     if (index % listofimages.response.results.length === 0) {
      //       ii = 0;
      //     }

      //full, raw, regular, small, thumb
      // i.src = listofimages.response.results[ii].urls.thumb;
      // i.dataset.src = listofimages.response.results[ii].urls.regular;
      // i.removeEventListener("click", function imageEnlarge(event) {
      // 	let img = event.target;
      // 	let d = document.createElement("div");
      // 	img = img.cloneNode(true);
      // 	img.src = img.dataset.src;

      // 	img.style.width = "";
      // 	img.style.height = "";
      // 	d.appendChild(img);

      // 	dialogs.alert(d.innerHTML, "Image");

      // 	// imageEnlarge(i, src);
      // });
      // i.addEventListener("click", function imageEnlarge(event) {
      // 	let img = event.target;
      // 	let d = document.createElement("div");
      // 	img = img.cloneNode(true);
      // 	img.src = img.dataset.src;

      // 	img.style.width = "";
      // 	img.style.height = "";
      // 	d.appendChild(img);

      // 	dialogs.alert(d.innerHTML, "Image");

      // 	// imageEnlarge(i, src);
      // });
      //     ii++;
      //   });
    })
    .catch(() => {
      console.log("something went wrong!");
    });
}

export default { unsplash, listofimages, changeImageThemes };
