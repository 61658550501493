import { useNavigate } from "react-router-dom";

import Footer from "./Footer";

import supportimg from "../images/support.jpg";
import canadahelps from "../images/canada-helps.png";
import interact from "../images/Interac.png";
import qrcode from "../images/QRCode.png";

const Give = () => {
  const navigate = useNavigate();
  return (
    <div className="w-100">
      <div className="w-75 m-auto d-flex flex-column align-items-center  justify-content-evenly">
        {/* <button className="btn btn-primary" onClick={() => navigate(-1)}>
        Go Back
      </button> */}

        <div>
          <img
            style={{ width: "100vw", marginTop: "-20px" }}
            src={supportimg}
            alt="support"
          />
        </div>
        <div className="title">
          <h1 className="wfh1">Support</h1>
        </div>
        <div className="about-container" style={{ lineHeight: "1.5" }}>
          <h3>Become a Regular Donor! </h3>

          <cite className="nblockquote">
            “Every good gift is from above.” James 1:1
          </cite>
          <br />
          <br />
          <p>
            As Christians, we believe that we have God’s spirit poured out upon
            us. And as people who have been gifted with the Holy Spirit we have
            been commissioned by Christ to help bring in God’s Kingdom. Your
            donation is a gift that can help us in our mission and shine light
            on the work of God here and now.
          </p>
          <div className="d-flex align-items-center text-start">
            <img src={interact} className="give-images" />
            <p>
              An Interac e-Transfer is a method to transfer money from one
              participating bank to another electronically. please use{" "}
              <span style={{ color: "red" }}>
                cw-stbede@toronto.anglican.ca
              </span>
              <br />
              To find out more about offering envelopes or about pre-authorized
              monthly giving (bank transfer/credit card), please contact the
              parish <a href="mailto:office@stbedesanglican.ca"> Office</a>.
            </p>
          </div>
          <div className="d-flex align-items-center text-start">
            <img src={canadahelps} className="give-images" />
            <p>
              Our page on{" "}
              <a
                href="https://www.canadahelps.org/en/charities/st-bedes-church"
                target="_blank"
              >
                CanadaHelps.org
              </a>{" "}
              — where you can contribute - an easy and secure way to
              electronically contribute to the work and life of St. Bede’s and
              its community as we seek God’s Kingdom come, on earth as it is in
              Heaven.
            </p>
            <img
              src={qrcode}
              alt="qrcode-canada-helps"
              className="give-images"
            />
          </div>
        </div>
      </div>
      <div className="blockquote-wrapper">
        <div className="blockquote">
          <h1>
            All shall give as they are able,{" "}
            <span style={{ color: "#ffffff" }}>according to the blessing </span>
            of the Lord your God that he has given you.
          </h1>
          <h4>
            &mdash;Deuteronomy
            <br />
            <em>16:17</em>
          </h4>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Give;
