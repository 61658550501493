import React from "react";

import cadvt from "../images/advtvideob.mp4";
import stbedeimg from "../images/stbedeold.jpeg";
import bird from "../images/bird.svg";

// import HeaderPage from "../components/HeaderPage";
import Footer from "../pages/Footer";
import PageCards from "../components/PageCards";
// import Carousel from "../pages/Carousel";

function Home(props) {
  console.log("Home props ", props);
  return (
    <div id="home-page">
      <div id="video-img">
        <div id="genericvideo" style={{ overflow: "hidden !important" }}>
          <video
            id="backgroundvideo"
            autoPlay
            muted
            loop
            playsInline
            src={cadvt}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              objectFit: "cover",
              verticalAlign: "middle",
              height: "auto",
            }}
          >
            ...
          </video>

          <video
            id="backgroundvideo"
            src={cadvt}
            style={{
              marginTop: "-100px",
              width: "100%",
              opacity: "0",
            }}
          ></video>
        </div>
      </div>

      <div className="bible-verse-hero">
        <img
          src={bird}
          style={{
            width: "25%",
            right: "0",
            position: "absolute",
            top: "0",
            opacity: "0.4",
            transform: "rotate3d(1, 1, 1, 45deg)",
            zIndex: 100,
          }}
        />
        <div className="container w-container">
          <div className="header-section-container">
            <div className="header-letter-wrapper blue-outline-text">
              <h1 className="wfh1 header-display-outline-blue">s</h1>
              <h1 className="wfh1 header-display-outline-blue">t</h1>
              <h1 className="wfh1 header-display-outline-blue">b</h1>
              <h1 className="wfh1 header-display-outline-blue">e</h1>
              <h1 className="wfh1 header-display-outline-blue">d</h1>
              <h1 className="wfh1 header-display-outline-blue">e</h1>
              {/* <h1 className="wfh1 header-display-outline-blue">v</h1>
              <h1 className="wfh1 header-display-outline-blue">e</h1> */}
            </div>
            <div className="header-letter-wrapper orange-outline-text">
              <h1 className="wfh1 header-display-outline-orange">s</h1>
              <h1 className="wfh1 header-display-outline-orange">t</h1>
              <h1 className="wfh1 header-display-outline-orange">b</h1>
              <h1 className="wfh1 header-display-outline-orange">e</h1>
              <h1 className="wfh1 header-display-outline-orange">d</h1>
              <h1 className="wfh1 header-display-outline-orange">e</h1>
              {/* <h1 className="wfh1 header-display-outline-orange">v</h1>
              <h1 className="wfh1 header-display-outline-orange">e</h1> */}
            </div>
            {/* <div className="header-letter-wrapper yellow-outline-text">
              <h1 className="wfh1 header-display-outline-yellow">s</h1>
              <h1 className="wfh1 header-display-outline-yellow">t</h1>
              <h1 className="wfh1 header-display-outline-yellow">b</h1>
              <h1 className="wfh1 header-display-outline-yellow">e</h1>
              <h1 className="wfh1 header-display-outline-yellow">d</h1>
              <h1 className="wfh1 header-display-outline-yellow">e</h1>
              <h1 className="wfh1 header-display-outline-yellow">v</h1>
              <h1 className="wfh1 header-display-outline-yellow">e</h1>
            </div> */}
            <div className="header-letter-wrapper">
              <h3 className="h3 cursive header">
                You are the light of the
                <br />
              </h3>
              <h3 className="h3 cursive header studio">
                A city built on a hill cannot be hid.
                <br />
              </h3>
              {/* background-image:
              url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQSmm-W6t6sNN77Q4Pe1rdTNogwLGXcq2ZKUw&usqp=CAU);
              background-size: cover; */}

              <h1 className="wfh1 display world">world</h1>
              {/* <h1 className="wfh1 display">c</h1>
              <h1 className="wfh1 display r">r</h1>
              <h1 className="wfh1 display e">e</h1>
              <h1 className="wfh1 display a">a</h1>
              <h1 className="wfh1 display t">t</h1>
              <h1 className="wfh1 display i">i</h1>
              <h1 className="wfh1 display v">v</h1>
              <h1 className="wfh1 display e-2">e</h1> */}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <PageCards />

        {/* <Carousel carouseltype={"text"} /> */}

        {/* <Carousel carouseltype={"image"} /> */}

        <Footer />
      </div>
    </div>
  );
}
export default Home;
