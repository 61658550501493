import React, { Component } from "react";
import Card from "react-bootstrap/Card";

import Footer from "../pages/Footer";
import "../css/App.css";
import blogdata from "../data/pastors-blog.json";
import epistleimg from "../images/epistle.jpg";

class Sunday extends Component {
  constructor(props) {
    super(props);
    console.log("Sunday", props);
  }

  render() {
    let bdata = blogdata;

    return (
      <div>
        {/* Pastors Sermons need to get thhe blog as well */}
        <div id="pastors-sermons-container" className="card-container-90">
          <Card
            id="pastors-sermons-index"
            className="outer"
            style={{ border: "none", maxHeight: "auto !important" }}
          >
            <h1 className="m-5">Sunday Sermons</h1>
            <Card.Body className="outer">
              {bdata.map((eachCard, index) => {
                if (eachCard.type !== "epistle") {
                  return (
                    <div
                      id={"sermon-" + index}
                      className="inner"
                      key={"sermon-" + index}
                    >
                      <div>
                        {eachCard.link === "" ? (
                          <Card.Img variant="top" src={epistleimg} />
                        ) : (
                          <iframe
                            width="100%"
                            height="50%"
                            src={eachCard.link}
                            // title="YouTube video player"
                            frameBorder="0"
                            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            // allowfullscreen
                            style={{
                              objectFit: "fill",
                              height: "185px",
                              padding: "5px",
                            }}
                          ></iframe>
                        )}

                        {/* <video
                          src={eachCard.link}
                          style={{
                            // position: "absolute",
                            // left: 0,
                            // top: 0,
                            width: "100%",
                            objectFit: "cover",
                            verticalAlign: "middle",
                            height: "auto",
                          }}
                        ></video> */}
                        <Card.Title>
                          <h4>{eachCard.headline}</h4>
                        </Card.Title>
                        <Card.Text id="published-author-dt">
                          {eachCard.author} by{" "}
                          {new Date(eachCard.timestamp).toLocaleDateString()}
                        </Card.Text>
                        <Card.Text id="sermon-abstract">
                          {eachCard.commentary}
                        </Card.Text>
                        {/* <Card.Text id="sermon-commentary">
                          {eachCard.commentary}
                        </Card.Text> */}
                      </div>
                    </div>
                  );
                }
              })}
            </Card.Body>
          </Card>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Sunday;
