import React, { Component } from "react";

import data from "../data/page-content.json";
import schema from "../data/schema.json";

import { Form } from "react-bootstrap";
import Footer from "./Footer";
class Admin extends Component {
  constructor(props) {
    super(props);
    console.log("Admin", props);
    this.state = {
      priests: {},
    };
  }

  handlePost(node, updNode) {
    node.map((n) => {
      if (n.id === parseInt(updNode.id)) {
        Object.keys(updNode).forEach(function (k) {
          n[k] = updNode[k];
          //   console.log(k + " - " + n[k]);
        });
      }
    });
    this.setState({ priests: node });
  }

  createForm(schema, data, datalabel) {
    return (
      <>
        <Form
          id={"edit-" + datalabel}
          style={{
            width: "50vw",
            textAlign: "left",
            lineHeight: "2",
            backgroundColor: "whitesmoke",
            padding: "30px",
            boxShadow: "1px 1px  1px 1px lightgray",
            marginTop: "10px",
          }}
          onSubmit={(event) => {
            event.preventDefault();

            const form = event.target;
            const submitter = event.target.querySelector(
              "button[value=submit]"
            );
            const formData = new FormData(form, submitter);

            let fdjson = {};
            for (const [key, value] of formData) {
              fdjson[key] = value;
            }
            this.handlePost(data, fdjson);
          }}
        >
          {schema.map((f) => {
            let r = (Math.random() + 1).toString(36).substring(2);
            let ele;
            switch (f.tag) {
              case undefined:
              case "input":
              case "label":
                ele = (
                  <input
                    type={f.type === undefined ? "text" : f.type}
                    id={datalabel + "-" + f.name}
                    key={r}
                    name={f.name}
                    readOnly={f.readonly}
                    className="form-control"
                    style={f.style}
                  />
                );
                break;
              case "textarea":
                ele = (
                  <textarea
                    type={f.type}
                    id={datalabel + "-" + f.name}
                    key={r}
                    name={f.name}
                    className="form-control"
                    maxLength={f.maxLength}
                  />
                );

                break;
              default:
                break;
            }

            r = (Math.random() + 1).toString(36).substring(2);
            return (
              <>
                <label key={r} htmlFor={datalabel + "-" + f.name}>
                  {f.name}
                </label>
                {ele}
              </>
            );
          })}
          <div className="d-flex justify-content-between w-100 p-4">
            <input className="btn btn-primary" type="submit" value="Submit" />

            <button className="btn btn-danger">Reset</button>
          </div>
        </Form>
        <button
          className="btn btn-success ms-3"
          onClick={() => {
            console.log(data);
          }}
        >
          Save
        </button>
      </>
    );
  }

  createLov(data, field, datalabel) {
    return (
      <select
        id="lov"
        size="10"
        style={{
          width: "30vw",
          margin: "20px",
          padding: "10px",
          border: "1px solid lightgray",
        }}
      >
        {data.map((p) => {
          return (
            <option
              value={p.id}
              key={datalabel + p.id}
              data-info={p}
              onClick={() => {
                Object.keys(p).map((kp) => {
                  try {
                    document.getElementById(datalabel + "-" + kp).value = p[kp];

                    if (kp === "image") {
                      console.log(p.image);
                      document.getElementById(datalabel + "-image").src =
                        p.image;
                    }
                  } catch (error) {
                    console.log(kp, error);
                  }
                });
              }}
            >
              {p[field]}
            </option>
          );
        })}
      </select>
    );
  }

  render() {
    return (
      <div>
        <h1>Admin page</h1>

        <div className="d-flex justify-content-evenly admin-container">
          <div>
            <h1>Card Details</h1>
            <img
              id="cards-image"
              src=""
              alt="cards-image"
              style={{ height: "300px" }}
            />
          </div>
          {this.createLov(data.cards, "title", "cards")}
          {this.createForm(schema.cards, data.cards, "cards")}
        </div>

        <div className="d-flex justify-content-evenly admin-container">
          <div>
            <h1>Priest Details</h1>
            <img
              id="priests-image"
              src=""
              alt="priests-image"
              style={{ height: "300px" }}
            />
          </div>
          {this.createLov(data.priests, "name", "priests")}
          {this.createForm(schema.priests, data.priests, "priests")}
        </div>

        <div className="d-flex justify-content-evenly admin-container">
          <div>
            <h1>Upcoming Details</h1>
            <img
              id="upcoming-image"
              src=""
              alt="upcoming-image"
              style={{ height: "300px" }}
            />
          </div>
          {this.createLov(data.upcoming, "title", "upcoming")}
          {this.createForm(schema.upcoming, data.upcoming, "upcoming")}
        </div>

        {/* <div
          id="admin-config"
          className="d-flex flex-row justify-content-evenly m-5"
        >
          <pre
            id="admin-json"
            style={{
              textAlign: "left",
              width: "100%",
              border: "1px solid gray",
            }}
            // s
            onKeyUp={(event) => {
              // console.log(JSON.parse(event.target.innerText));
              try {
                JSON.parse(event.target.innerText);
                event.target.style.color = "black";
              } catch (error) {
                // console.log(error);
                event.target.style.color = "red";
              }
            }}
          >
            {JSON.stringify(data, null, 4)}
          </pre>
          <button className="btn btn-primary">Save</button>
        </div> */}

        <Footer />
      </div>
    );
  }
}

export default Admin;
