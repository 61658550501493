import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";

import "../css/App.css";
import "../css/farco.css";
// import "../css/wf.css";

import Auction from "../pages/Auction";
import StBNavBar from "./StBNavBar";
import Home from "../pages/Home";
import Give from "../pages/Give";
import About from "../pages/About";
import Sunday from "../pages/Sunday";
import Admin from "../pages/Admin";

let usrObj = {};

class App extends Component {
  // function App() {
  //   const [user, setUser] = useState(null);
  //   console.log(user);

  constructor() {
    super();
    this.state = {
      user: "",
      date: new Date(),
      sbscreen: "Home",
    };
    this.setState = this.setState.bind(this);
    this.showScreen = this.showScreen.bind(this);
    this.setGoogleUser = this.setGoogleUser.bind(this);
    // this.createPicker = this.createPicker.bind(this);
    // this.handleCallbackResponse = this.handleCallbackResponse.bind(this);
    // this.showSignOnWindow = this.showSignOnWindow.bind(this);

    console.log("App State", this.state);
  }

  componentDidMount() {
    console.log("App componentDidMount", this.state);
    this.setGoogleUser();
    // console.log("componentDidMount", usrObj, this.state.user);

    // this.timerID = setInterval(() => {
    //   if (document.hasFocus()) {
    //     console.log("In focus");
    //     this.tick();
    //   } else {
    //     console.log("No focus");
    //   }
    // }, 60000);
  }

  tick() {
    this.setState({
      date: new Date(),
    });
    console.log(this.state.date);
    // window.location.reload();
  }

  componentDidUpdate() {
    // console.log("componentDidUpdate", usrObj, this.state.user);
    // <StBNavBar props={this.state.user} />;
  }

  // function setScreen(scr) {
  //   debugger;
  //   console.log("screen", scr);
  //   if (this.state.screen !== scr) {
  //     this.setState({ screen: scr });
  //   }
  // }

  handleCallbackResponse = (response) => {
    // if (
    //   ((this.state.user === null || this.state.user == {}) &&
    //     sessionStorage["sb-user"] === undefined) ||
    //   sessionStorage["sb-user"] === ""
    // ) {
    if (
      sessionStorage["sb-user"] === undefined ||
      sessionStorage["sb-user"] === ""
    ) {
      try {
        // console.log("Encoded JWT IID token :" + response.credential);
        usrObj = jwt_decode(response.credential);
        usrObj.super = false;

        sessionStorage.setItem("sb-user", usrObj.given_name);
        sessionStorage.setItem("sb-user-json", JSON.stringify(usrObj));

        // let ele = document.getElementById("whoami");
      } catch (error) {
        console.log(error);
        usrObj = response;
      }
    } else {
      usrObj = response;
    }
    this.setState({ user: usrObj });

    // console.log(usrObj, this.state.user);
    // document.getElementById("whoami").hidden = true;
    // document.getElementById("user").hidden = false;
    // document.getElementById("user-name").innerHTML =
    //   "Welcome " + usrObj.given_name;
    // document.getElementById("user").dataset.emailId = usrObj.email;
    // document.getElementById("user-pic").src = usrObj.picture;
  };

  setGoogleUser() {
    /* global google*/
    console.log("Google global login");
    try {
      if (sessionStorage.getItem("sb-user") === null) {
        // document.getElementById("whoami").hidden = false;
        // document.getElementById("user").hidden = true;

        google.accounts.id.initialize({
          client_id:
            "191335517377-gvrgsjkq0a382n684a4ck4pvsksltlsh.apps.googleusercontent.com",
          callback: this.handleCallbackResponse,
        });

        google.accounts.id.renderButton(document.getElementById("whoami"), {
          theme: "outline",
          size: "small",
        });

        google.accounts.id.prompt();
      } else {
        this.setState({
          user: JSON.parse(sessionStorage.getItem("sb-user-json")),
        });
        this.handleCallbackResponse(
          JSON.parse(sessionStorage.getItem("sb-user-json"))
        );
      }
    } catch (error) {
      console.log(error);
    }
    // console.log("useEffect", user.given_name, user.email);
  }

  showSignOnWindow() {
    try {
      [...document.getElementsByTagName("span")]
        .filter(
          (el) => el.textContent.trim() === "Sign in with Google".trim()
        )[0]
        .click();
    } catch (error) {
      console.log(error);
    }
  }
  showScreen(sbpage) {
    // console.log(sbpage);
    this.setState({ sbscreen: sbpage });
  }

  render() {
    return (
      <div>
        <StBNavBar user={this.state.user} showScreen={this.showScreen} />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/here" element={<>Start Here</>} />
          <Route path="/sundays" element={<Sunday />} />
          <Route path="/give" element={<Give />} />
          <Route
            path="/auction"
            element={
              <Auction
                setGoogleUser={this.setGoogleUser}
                user={this.state.user}
              />
            }
          />
          <Route path="/about" element={<About />} />
          <Route path="/admin" element={<Admin />} />
          {/* <Route path="/products/:slug" element={<ProductDetails />} /> */}
          {/* <Route path="*" element={<NoMatch />} /> */}
        </Routes>

        {/* {this.state.sbscreen === "Home" ? (
          <Home user={this.state.user} />
        ) : this.state.sbscreen === "Auction" ? (
          <Auction
            setGoogleUser={this.setGoogleUser}
            user={this.state.user}
          />
        ) : (
          <>We need a page to load</>
        )} */}
        {/* <Prayers id={1} /> */}
      </div>
    );
  }
}

export default App;
