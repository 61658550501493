import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { createRoot } from "react-dom/client";

import jwt_decode from "jwt-decode";

import App from "./components/App";

let usrObj = "";

function GoogleSignOn() {
  const [user, setUser] = useState({});

  //   useEffect(() => {
  //     /* global google*/
  //     google.accounts.id.initialize({
  //       client_id:
  //         "191335517377-gvrgsjkq0a382n684a4ck4pvsksltlsh.apps.googleusercontent.com",
  //       callback: handleCallbackResponse,
  //     });

  //     google.accounts.id.renderButton(document.getElementById("signInDiv"), {
  //       theme: "outline",
  //       //   type: "icon",
  //       size: "large",
  //     });

  //     google.accounts.id.prompt();
  //   }, []);

  function handleCallbackResponse(response) {
    console.log("Encoded JWT IID token :" + response.credential);
    usrObj = jwt_decode(response.credential);

    setUser({ usrObj });

    // document.getElementById("signInDiv").hidden = true;
  }

  function handleSignOut(event) {
    setUser({});
    // document.getElementById("signInDiv").hidden = false;
  }

  return <App tab={"home"} user={usrObj} />;
}

function AppWithCallbackAfterRender() {
  //   useEffect(() => {
  //     /* global google*/
  //     google.accounts.id.initialize({
  //       client_id:
  //         "191335517377-gvrgsjkq0a382n684a4ck4pvsksltlsh.apps.googleusercontent.com",
  //       callback: GoogleSignOn.handleCallbackResponse,
  //     });
  //     google.accounts.id.renderButton(document.getElementById("signInDiv"), {
  //       theme: "outline",
  //       size: "large",
  //     });
  //     google.accounts.id.prompt();
  //   }, []);
  //   console.log(usrObj);
  //   return <App tab={"home"} user={{ usrObj }} />;
}

// GoogleSignOn();

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
    <GoogleSignOn />
  </BrowserRouter>
);

// ReactDOM.render(<App />, document.getElementById("root"));
