import React, { useEffect, useState } from "react";

import {
  ArrowUpSquareFill,
  BoxArrowUpRight,
  Google,
  InfoCircle,
  PencilFill,
} from "react-bootstrap-icons";

import Disclaimer from "../components/Disclaimer";

import dialogs from "raw-dialogs";
import refresh from "../images/refresh-circle.svg";

// let fetchURL = "http://localhost:3001/";
// let fetchURL = "http://192.168.2.32:3001/";
let fetchURL = "https://api.stbedesanglican.ca/";
// let fetchURL = "https://stbedeswebpage.nn.r.appspot.com/";

// let bidHistory;

function Auction(props) {
  const [items, setItems] = useState([]);
  const [config, setConfig] = useState([]);
  const [history, setHistory] = useState([]);
  // const [user, setuser] = useState("");
  console.log("Auction ", props);

  useEffect(() => {
    console.log(new Date().toLocaleString(), "useEffect");

    fetchItems().catch(console.error);
    fetchConfig().catch(console.error);
    fetchHistory().catch(console.error);

    document.getElementById("cover-screen").hidden =
      localStorage.getItem("disclaimer") === "accepted" ? true : false;

    let timerID = setInterval(() => {
      if (document.hasFocus()) {
        console.log("In focus :", new Date().toLocaleTimeString());
        tick();
      } else {
        console.log("No focus", new Date().toLocaleTimeString());
      }
    }, 60000);
  }, []);

  function tick() {
    // this.setState({
    //   date: new Date(),
    // });
    if (document.getElementById("refresh-time") !== null) {
      fetchItems();
      document.getElementById("refresh-time").innerHTML =
        new Date().toLocaleTimeString();
    }
    // window.location.reload();
  }

  async function fetchItems() {
    let options = {
      // method: "get",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-store",
        user: sessionStorage["sb-user"],
      },
    };
    console.log(options);
    const data = await fetch(fetchURL, options).then(async (response) => {
      const json = await response.json().then((json) => {
        console.log("from fetchItems ", json);
        // debugger;
        if (!props.user.super) {
          json = json.filter((j) => j.status != "Closed");
        }

        setItems(json);
        document.getElementById("refresh-time").innerHTML =
          new Date().toLocaleTimeString();
        return json;
      });
    });
  }

  async function fetchConfig() {
    let options = {
      method: "get",
      headers: { "Content-Type": "application/json" },
    };
    // console.log(options);
    const data = await fetch(fetchURL + "config/").then(async (response) => {
      const json = await response.json().then((json) => {
        // console.log("from fetchConfig ", json);
        setConfig(json);

        let sup = json.supers.filter(
          (u) => u === document.getElementById("user").dataset.emailId
        ).length;

        if (props.user != "") {
          // debugger;
          props.user.super = sup > 0 ? true : false;
          console.log("SUPER", props.user);
        }

        document.getElementById("items-add-modify").style.display =
          sup > 0 ? "" : "none";

        document.getElementById("user").dataset.super = sup > 0 ? true : false;

        return json;
      });
    });
  }

  async function fetchHistory() {
    let options = {
      // method: "get",
      headers: {
        "Content-Type": "application/json",
        user: sessionStorage["sb-user"],
      },
    };
    // console.log(options);
    const data = await fetch(fetchURL + "history", options).then(
      async (response) => {
        const json = await response.json().then((json) => {
          // console.log("from fetchHistory ", json);
          setHistory(json);
          return json;
        });
      }
    );
  }

  async function getBase64(file, img) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      // cb(reader.result);
      img.dataset.base64 = reader.result;
      // console.log(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  // class Auction extends Component {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       coverScreen: false,
  //     };
  //   }

  //   componentDidMount() {}

  //   componentDidUpdate() {}

  // const timerID = setInterval(() => fetchItems(), 60000);
  // function tick() {
  //   fetchItems();
  // }
  async function handlePostData() {
    //Create new items
    // debugger;
    let options = {
      method: "post",
      headers: { "Content-Type": "application/json" },
    };
    let senddata = {};
    senddata.id = document.getElementById("item-title-select").value;
    if (senddata.id === "") {
      senddata.id = 0;
    }
    senddata.title = document.getElementById("item-title").value;
    senddata.text = document.getElementById("item-desc").value;
    senddata.link = document.getElementById("item-link").value;
    senddata.bid = document.getElementById("item-bid").value;
    if (document.getElementById("item-img").files.length != 0) {
      senddata.image = document.getElementById("item-img").files[0].name;
      senddata.imagebase64 =
        document.getElementById("item-image").dataset.base64;
    }
    senddata.status = document.getElementById("item-status").value;

    options.body = JSON.stringify(senddata);
    // console.log(options);
    // this.setState({ coverScreen: true });
    // await fetch("https://api.stbedesanglican.ca/", options).then(
    document.getElementById("item-errors").style.color = "Red";
    await fetch(fetchURL, options)
      .then(async (data) => {
        document.getElementById("item-errors").innerHTML =
          data.status + " " + data.statusText;
        console.log(data.status + " " + data.statusText);

        if (data.status === 201) {
          document.getElementById("item-errors").style.color = "Green";
          let json = await data.json().then((json) => {
            // debugger;
            console.log("handlePostData ", json);

            clearForm("close");

            window.location.reload();
            // setItems(json);
            // return json;
            // fetchItems().catch((error) => {
            //   console.log("handlePostData", error);
            // });

            // fetchItems()
            //   .then(async (response) => {
            //     let json = await data.json();
            //     setItems(json);
            //   })
            //   .catch((error) => {
            //     console.log("1", error);
            //   });
          });
        }
      })
      .catch((err) => {
        console.log("2", err);
        // document.getElementById("ops-items-holder").hidden = false;
      });
  }

  async function handlePutData(senddata) {
    //check if there is already a bit made

    console.log(fetchURL + "?id=" + senddata.no);
    let sdata;
    await fetch(fetchURL + "?id=" + senddata.no).then(async (response) => {
      const json = await response.json().then((json) => {
        console.log("fetching singleItem ", json);
        sdata = json[0];
      });
    });

    //Only for update or replace
    // debugger;
    let options = {
      method: "put",
      headers: { "Content-Type": "application/json" },
    };
    options.body = JSON.stringify(senddata);

    if (props.user.name === sdata.name) {
      dialogs.alert("You are the current bidder.", "St. Bede's");
    } else {
      if (senddata.cbid !== sdata.bid) {
        dialogs.alert("The current bid is $" + sdata.bid, "St. Bede's");
        fetchItems();
      } else {
        makethecall();
      }

      async function makethecall() {
        await fetch(fetchURL + "bid", options)
          .then(async (data) => {
            console.log(data.status + " " + data.statusText);
            if (data.status === 200) {
              let json = await data.json().then((json) => {
                // debugger;
                setItems(json);

                return json;
              });
            }
          })
          .catch((err) => {
            console.log("2", err);
          });
        // dialogs.closeOverlay();
      }
    }
  }

  function clearForm(op) {
    // document.getElementById("ops-items-holder").reset();

    document.getElementById("item-errors").innerHTML = "";
    document.getElementById("item-title-select").value = "";
    document.getElementById("item-title").value = "";
    document.getElementById("item-desc").value = "";
    document.getElementById("item-link").value = "";
    document.getElementById("item-bid").value = "";
    document.getElementById("item-status").value = "Open";
    document.getElementById("item-image").src = "";
    document.getElementById("item-image").dataset.base64 = "";
    if (op === "close") {
      document.getElementById("ops-items-holder").hidden = true;
    }
  }

  //   render() {
  return (
    <div>
      <Disclaimer />

      <div id="silent-auction">
        The silent auction, both online and in the Chapel will{" "}
        <strong>
          commence on Oct. 23, 2023 and conclude on October 28th at 8 p.m.{" "}
        </strong>
        As we mark a century of faith, love, and community, let's come together
        in this unique way. Your unwavering support has been the cornerstone of
        our journey for the past 100 years, and this Silent Auction will
        undoubtedly add to our cherished memories. Thank you for being an
        enduring part of our journey. We pray for God's continued blessings on
        our congregation and on each of you.
      </div>
      <div className="w-50 d-none">
        <ul>
          <li>
            Info <InfoCircle />
          </li>
          <li>
            <img src={refresh} style={{ width: "40px" }} /> to reterieve latest
            data{" "}
          </li>
          <li>
            <ArrowUpSquareFill style={{ fontSize: "xx-large" }} /> to bid
          </li>
        </ul>
        <button
          onClick={() => {
            // createPicker();
          }}
        ></button>
      </div>

      <div className="d-flex flex-row justify-content-between w-75 m-auto">
        <img
          src={refresh}
          style={{ width: "40px", cursor: "pointer" }}
          title="Refresh to get latest information"
          onClick={() => {
            fetchConfig().catch((error) => {
              console.log("ArrowRepeat", error);
            });
            fetchItems().catch((error) => {
              console.log("ArrowRepeat", error);
            });
            dialogs.alert("Items refreshed", "St. Bede's");
          }}
        />
        {/* <ArrowRepeat
          style={{ fontSize: "x-large", cursor: "pointer" }}
          title="Refresh to get latest information"
        ></ArrowRepeat> */}

        <div className="d-flex align-items-center">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio1"
              value="option1"
              onChange={() => {
                console.log("click");
                document
                  .getElementById("item-container")
                  .classList.remove("d-none");
                document
                  .getElementById("table-container")
                  .classList.add("d-none");
              }}
            />
            <label className="form-check-label" htmlFor="inlineRadio1">
              Cards
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="inlineRadio2"
              value="option2"
              onChange={() => {
                document
                  .getElementById("item-container")
                  .classList.add("d-none");
                document
                  .getElementById("table-container")
                  .classList.remove("d-none");
              }}
            />
            <label className="form-check-label" htmlFor="inlineRadio2">
              Table
            </label>
          </div>
          <span className="d-none" id="refresh-time">
            time
          </span>
        </div>
        <select className="form-select w-25 d-none">
          <option>Open</option>
          <option>Upcoming</option>
          <option>Closed</option>
        </select>
      </div>

      {/* <button
        className="btn btn-primary"
        onClick={() => {
          fetchConfig();
        }}
      >
        Config
      </button> */}

      <div
        id="item-container"
        className="d-flex flex-row flex-wrap justify-content-center"
      >
        {items.length == 0
          ? "Loading ... Please Wait !!"
          : items.map((i, index) => {
              return (
                <div
                  key={index}
                  id={"item-holder-" + index}
                  className={` d-flex flex-column item-holder justify-content-between ${
                    i.status.toLowerCase() + "Item"
                  }`}
                  data-index={index}
                  // style={{ userSelect: }
                >
                  <div
                    className="d-flex flex-row h-75 item-container"
                    style={{ zIndex: "10" }}
                  >
                    <div className="d-flex flex-column align-items-baseline position-absolute">
                      {i.link !== "" ? (
                        <a
                          href={i.link}
                          target="_blank"
                          style={{ margin: "10px" }}
                        >
                          <BoxArrowUpRight />
                        </a>
                      ) : (
                        ""
                      )}

                      {/* {document.getElementById("user").dataset.super ===
                      "false" ? (
                        <></>
                      ) : (
                        <div>
                          <PencilFill
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              let eles = [
                                ...document
                                  .getElementById("item-holder-" + index)
                                  .querySelectorAll(".editable"),
                              ];
                              console.log(eles);

                              eles.map((e) => {
                                e.setAttribute("contentEditable", true);
                              });

                              event.target.nextElementSibling.removeAttribute(
                                "hidden"
                              );
                              event.target.setAttribute("hidden", true);
                            }}
                          ></PencilFill>
                          <Save
                            hidden
                            onClick={(event) => {
                              console.log("Go to save");
                            }}
                          />
                        </div>
                      )} */}
                    </div>
                    <div className="p-2 w-75 d-flex flex-column justify-content-evenly align-items-center item-info">
                      <div className="h-75 d-flex align-items-center">
                        <img
                          id={"item-image-" + index}
                          className="item-img editable"
                          src={"data:image/png;base64," + i.image}
                          alt={i.title}
                          onClick={(event) => {
                            let t = event.target;
                            if (t.getAttribute("contentEditable") === "true") {
                              t.nextElementSibling.click();
                            }
                          }}
                        />
                        <input
                          type="file"
                          hidden
                          onChange={async (event) => {
                            console.log(event.target.files[0]);
                            let img = document.getElementById(
                              "item-image-" + index
                            );
                            let imgsrc = URL.createObjectURL(
                              event.target.files[0]
                            );
                            img.src = imgsrc;

                            await getBase64(event.target.files[0], img);
                            // .then((results) => {
                            //   img.dataset.base64 = results;
                            // });
                          }}
                        ></input>
                      </div>
                    </div>
                    <div className="d-flex flex-column item-info ">
                      <div className="w-100 d-flex flex-row justify-content-evenly align-items-center ">
                        <h1 className="font-headline editable">{i.title}</h1>

                        {/* <a
                          href={i.link}
                          target="_blank"
                          style={{ margin: "10px" }}
                        >
                          <BoxArrowUpRight />
                        </a> */}
                      </div>
                      <p className="editable">{i.text}</p>
                    </div>
                  </div>
                  <div style={{ zIndex: "10" }}>
                    <div className="w-100 p-2 d-flex flex-row justify-content-between align-items-center">
                      <p id="current-bid">${i.bid}</p>
                      <span>{i.name}</span>
                      {sessionStorage.length === 0 ? (
                        <div id="logon">
                          <Google
                            onClick={() => {
                              console.log("Redrect to sign in");
                            }}
                          />
                        </div>
                      ) : (
                        <ArrowUpSquareFill
                          hidden={
                            i.status.toLowerCase() !== "open" ? true : false
                          }
                          style={{ fontSize: "xx-large", cursor: "pointer" }}
                          className=""
                          onClick={(event) => {
                            // debugger;
                            let eid = document.getElementById("user"); //this.state.user;
                            // if (eid !== "" && eid !== undefined) {
                            if (!eid.hidden) {
                              let toSend = {
                                id: eid.dataset.emailId,
                                no: i.id,
                                name: eid.dataset.name,
                                cbid: i.bid,
                              };
                              console.log(toSend);
                              handlePutData(toSend);
                            } else {
                              dialogs.alert(
                                "Please signon to Bid !!",
                                "St. Bede's"

                                // props.showSignOnWindow
                              );
                            }
                            // this.setState({ bid: 1 });
                          }}
                        ></ArrowUpSquareFill>
                      )}
                    </div>
                    <div style={{ fontSize: "small" }}>
                      {document.getElementById("user").dataset.super ===
                      "true" ? (
                        <select
                          style={{
                            width: "98%",
                            border: "none",
                            margin: "1px 10px 1px 5px",
                            background: "transparent",
                            fontSize: "small",
                          }}
                        >
                          {[
                            ...history
                              .filter((b) => b.id === i.id)
                              .sort((a, b) =>
                                a.timestamp < b.timestamp ? 1 : -1
                              ),
                          ].map((b, index) => {
                            if (b.id === i.id) {
                              return (
                                <option key={index}>
                                  {b.name} {"     "} {b.bid} {"     "}{" "}
                                  {new Date(b.timestamp).toLocaleString()}
                                </option>
                              );
                            }
                          })}
                        </select>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <img
                    className="item-img-overlay "
                    src={"data:image/png;base64," + i.image}
                    alt={i.title}
                    draggable="false"
                  />
                </div>
              );
            })}
      </div>

      <div id="table-container" className="mt-5 d-none">
        <table className="table table-hover  w-75 m-auto">
          <thead>
            <tr className="table-dark">
              <td colSpan={6} style={{ textAlign: "right" }}>
                {items.length}
              </td>
            </tr>
            <tr className="table-dark">
              <th>ID</th>
              <th>Title</th>
              <th>Bid</th>
              <th>Bidder</th>
              <th>Status</th>
              <th></th>
              {/* <th>Time</th> */}
            </tr>
          </thead>
          <tbody>
            {items.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{i.id}</td>
                  <td>{i.title}</td>
                  <td>{i.bid}</td>
                  <td>{i.name}</td>
                  <td>{i.status}</td>
                  <td>
                    {sessionStorage.length === 0 ? (
                      <div id="logon">
                        <Google
                          onClick={() => {
                            console.log("Redrect to sign in");
                          }}
                        />
                      </div>
                    ) : (
                      <ArrowUpSquareFill
                        hidden={
                          i.status.toLowerCase() !== "open" ? true : false
                        }
                        style={{ fontSize: "xx-large", cursor: "pointer" }}
                        className=""
                        onClick={(event) => {
                          // debugger;
                          let eid = document.getElementById("user"); //this.state.user;
                          // if (eid !== "" && eid !== undefined) {
                          if (!eid.hidden) {
                            let toSend = {
                              id: eid.dataset.emailId,
                              no: i.id,
                              name: eid.dataset.name,
                              cbid: i.bid,
                            };
                            console.log(toSend);
                            handlePutData(toSend);
                          } else {
                            dialogs.alert(
                              "Please signon to Bid !!",
                              "St. Bede's"

                              // props.showSignOnWindow
                            );
                          }
                          // this.setState({ bid: 1 });
                        }}
                      ></ArrowUpSquareFill>
                    )}
                  </td>
                  {/* <td style={{ fontSize: "small" }}>
                    {new Date(i.timestamp).toLocaleString()}
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div
        id="ops-items-holder"
        hidden
        // onSubmit={async () => await handlePostData()}
      >
        <h2 id="ops-header">Add Item</h2>
        <span style={{ fontSize: "small", color: "purple" }}>
          This will be restricted to one person for adding or modifying the
          items on BID.
        </span>
        <br />
        <br />
        <div>
          <div className="form-floating mb-3">
            <select
              id="item-title-select"
              className="form-select"
              onChange={(event) => {
                let sv = parseInt(event.target.value);

                document.getElementById("ops-header").innerHTML =
                  (sv === 0 ? "New " : "Modify") + " Item";

                document.getElementById("item-add-modify").innerHTML =
                  sv === 0 ? "Add" : "Update";

                if (sv != 0) {
                  items.map((i) => {
                    if (i.id === sv) {
                      document.getElementById("item-title").value = i.title;
                      document.getElementById("item-desc").value = i.text;
                      document.getElementById("item-link").value = i.link;
                      document.getElementById("item-bid").value = i.bid;
                      document.getElementById("item-image").src =
                        "data:image/png;base64," + i.image;
                    }
                  });
                } else {
                  clearForm();
                }
              }}
            >
              <option value="0"></option>
              {items.map((i) => {
                return (
                  <option key={i.id} value={i.id}>
                    {i.title}
                  </option>
                );
              })}
            </select>
            <label htmlFor="item-tile">Items</label>
          </div>
          <div className="form-floating mb-3">
            <input
              id="item-title"
              className="form-control"
              type="text"
              placeholder="Title"
              required
            />
            <label htmlFor="item-tile">Title</label>
          </div>
          <div className="form-floating mb-3">
            <textarea
              id="item-desc"
              className="form-control"
              type="text"
              placeholder="Description"
              rows="3"
              maxLength={250}
              required
              style={{ height: "100%" }}
            />
            <label htmlFor="item-desc">Description (250 chars max)</label>
          </div>
          <div className="form-floating mb-3">
            <input
              id="item-link"
              className="form-control"
              type="url"
              placeholder="Link"
            />
            <label htmlFor="item-tile">Link</label>
          </div>
          <div className="form-floating mb-3">
            <input
              id="item-bid"
              className="form-control"
              type="number"
              placeholder="Bid Amount"
              required
            />
            <label htmlFor="item-bid">Bid</label>
          </div>
          <div className="form-floating mb-3">
            <select id="item-status" className="form-select" required>
              <option key="1" className="option">
                Open
              </option>
              <option key="2" className="option">
                Closed
              </option>
              <option key="3" className="option">
                Upcoming
              </option>
            </select>
            <label htmlFor="item-bid">Status</label>
          </div>
          <input
            id="item-img"
            className="form-control"
            type="file"
            accept="image/*"
            placeholder="Item image"
            onChange={async (event) => {
              console.log(event.target.files[0]);
              let img = document.getElementById("item-image");
              let imgsrc = URL.createObjectURL(event.target.files[0]);
              img.src = imgsrc;

              await getBase64(event.target.files[0], img);
              // .then((results) => {
              //   img.dataset.base64 = results;
              // });
            }}
          />
        </div>
        <br />
        <span style={{ color: "red" }} id="item-errors"></span>
        <div id="item-button-holder" className="d-flex justify-content-between">
          <button
            id="item-add-modify"
            className="btn btn-primary"
            style={{ display: "block" }}
            onClick={async () => await handlePostData()}
          >
            Add
          </button>
          <div className="w-25 d-flex justify-content-between">
            <button
              className="btn btn-secondary"
              style={{ display: "block" }}
              onClick={() => {
                clearForm("close");
              }}
            >
              Close
            </button>
            <button
              id="ops-reset"
              className="btn btn-danger"
              style={{ display: "block" }}
              onClick={() => {
                clearForm("reset");
              }}
            >
              Reset
            </button>
          </div>
        </div>
        <br />
        <img
          className="form-control"
          id="item-image"
          src=""
          alt="Image shows here"
        />
      </div>

      <div style={{ height: "100px" }}></div>

      {props.user.super === true ? (
        <div>
          <div style={{ marginBottom: "100px" }}>
            <table className="table table-striped w-75 m-auto ">
              <thead className="table-dark">
                <tr>
                  <th>Commentaries</th>
                  <th> Timestamp</th>
                  {/* {Object.keys(history[history.length - 1]).map((o) => {
                  return <th>{o}</th>;
                })} */}
                </tr>
              </thead>
              <tbody>
                {[
                  ...history.sort((a, b) =>
                    a.timestamp < b.timestamp ? 1 : -1
                  ),
                ].map((h, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-start">
                        {h.name}
                        {h.name === undefined ? "Starting " : " "} bid
                        <span style={{ color: "red" }}> ${h.bid}</span> on{" "}
                        {h.title}
                      </td>
                      <td>{new Date(h.timestamp).toLocaleString()}</td>
                      {/* {Object.keys(h).map((o) => {
                      return (
                        <td>
                          {o === "timestamp"
                            ? new Date(h[o]).toLocaleString()
                            : h[o]}
                        </td>
                      );
                    })} */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div id="add-items">
            <PencilFill
              id="items-add-modify"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.scrollTo(0, 0);
                document.getElementById("ops-items-holder").hidden = false;
                console.log(items);
              }}
            />
          </div>

          <div
            id="auction-config"
            className="d-flex  flex-row justify-content-evenly w-75 m-auto"
          >
            <pre
              id="config-json"
              style={{
                textAlign: "left",
                width: "75%",
                border: "1px solid gray",
              }}
              contentEditable
              onKeyUp={(event) => {
                // console.log(JSON.parse(event.target.innerText));
                try {
                  JSON.parse(event.target.innerText);
                  event.target.style.color = "black";
                } catch (error) {
                  // console.log(error);
                  event.target.style.color = "red";
                }
              }}
            >
              {JSON.stringify(config, null, 4)}
            </pre>
            <button className="btn btn-primary">Save</button>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      <div style={{ height: "100px" }}></div>
    </div>
  );
  //   }
}

export default Auction;
