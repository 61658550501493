import React, { Component } from "react";

import "../css/App.css";

import data from "../data/page-content.json";
import blogdata from "../data/pastors-blog.json";

import Card from "react-bootstrap/Card";
import unsplash from "./unsplash";
import epistleimg from "../images/epistle.jpg";

class PageCards extends Component {
  render() {
    let carddata = data.cards;
    let nextup = data.upcoming;
    let history = data.history;
    let bdata = blogdata;
    // carddata.splice(0, 1);
    unsplash.changeImageThemes("moons");
    console.log(unsplash.listofimages);

    //https://drive.google.com/uc?export=view&id=
    return (
      <div>
        <div className="d-flex flex-column">
          <div id="upnext-card-container" className="card-container-90 ">
            <Card id="upnext-card-index" style={{ border: "none" }}>
              <h1 className="m-5">Upcoming Events</h1>
              <Card.Body className="d-flex flex-row justify-content-evenly align-items-center flex-wrap upcoming">
                {nextup.map((eachCard, index) => {
                  if (eachCard.active === true) {
                    return (
                      <div
                        id={"upnext-" + index}
                        key={"upnext-" + index}
                        className="w-50 d-flex justify-content-evenly align-items-center"
                      >
                        {/* <div
                        className="w-50"
                        style={{ background: "url(" + eachCard.image + ")" }}
                      ></div> */}
                        {/* <img
                        variant="top"
                        src={eachCard.image}
                        className="cardimgovl"
                      /> */}
                        <div className="w-100 p-2">
                          <Card.Title>
                            <h4>
                              {eachCard.title} {eachCard.special ? "**" : ""}
                            </h4>
                          </Card.Title>

                          <Card.Text>{eachCard.when}</Card.Text>
                          <Card.Text>{eachCard.who}</Card.Text>
                          <br />
                          <Card.Text>{eachCard.text}</Card.Text>
                          <hr />
                        </div>
                      </div>
                    );
                  }
                })}
              </Card.Body>
            </Card>
          </div>
          {/* Cards  */} <h1 className="m-5">Our Ministries</h1>
          <div id="card-container" className="card-container">
            {carddata.map((eachCard, index) => {
              return (
                <Card
                  className="card-body stbcard outer"
                  id={"content-" + index}
                  key={"content-" + index}
                  onClick={(event) => {
                    // event.target.style.position = "absolute";
                  }}
                >
                  <Card.Body className="collapsed inner ">
                    <Card.Img
                      variant="top"
                      src={eachCard.image}
                      // style={{ position: "absolute", height: "200px" }}
                    />
                    <Card.Title>
                      <h4>{eachCard.title}</h4>
                    </Card.Title>
                    <Card.Text>{eachCard.text}</Card.Text>
                    {/* <div>
                    <ArrowsAngleExpand data-card-id={"card-" + index} />
                  </div> */}
                  </Card.Body>
                </Card>
              );
            })}
          </div>
          {/* Pastors Epistles */}
          <div
            id="pastors-epistles-container"
            className="card-container-90 d-none"
          >
            <Card
              id="pastors-epistles-index"
              className="outer"
              style={{ border: "none" }}
            >
              <h1 className="m-5">Pastors Epistles</h1>
              <Card.Body className="outer">
                {bdata.map((eachCard, index) => {
                  if (eachCard.type === "epistle") {
                    return (
                      <div
                        id={"epistles-" + index}
                        className="inner"
                        key={"epistles-" + index}
                      >
                        <div>
                          <Card.Img variant="top" src={epistleimg} />

                          <Card.Title>
                            <h4>{eachCard.headline}</h4>
                          </Card.Title>
                          <Card.Text id="published-author-dt">
                            {eachCard.author} by{" "}
                            {new Date(eachCard.timestamp).toLocaleDateString()}
                          </Card.Text>
                          <Card.Text
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{
                              __html: eachCard.commentary,
                            }}
                          ></Card.Text>
                          {/* <Card.Text id="sermon-commentary">
                          {eachCard.commentary}
                        </Card.Text> */}
                        </div>
                      </div>
                    );
                  }
                })}
              </Card.Body>
            </Card>
          </div>
          {/* Picture Roll */}
          {/* <div id="pic-roll-card-container" className="view-90">
          <Card id="history-card-index">
            <h1 className="m-1">Picture roll</h1>
            <Card.Body
              className="d-flex flex-row justify-content-between align-items-centers view-80"
              style={{ overflow: "scroll" }}
            >
              <div
                id="pic-roll-img-container"
                // style={{ backgroundColor: "white", width: "50%" }}
              >
                <div className=" d-flex justify-content-grid flex-wrap">
                  {data.pictures.map((pic, index) => {
                    return (
                      <img
                        key={"pic-roll-" + index}
                        className="picture-roll"
                        src={pic}
                        alt="pic"
                      />
                    );
                  })}
                </div>
              </div>
            </Card.Body>
          </Card>
        </div> */}
        </div>
      </div>
    );
  }
}

export default PageCards;
