import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import { Escape } from "react-bootstrap-icons";
import logo from "../images/StBedes-logo-100-colour.png";

class StBNavBar extends Component {
  constructor(props) {
    super(props);

    console.log("Navbar props", this.props);
    // if (this.props.user == "") {
    //   this.props.setGoogleUser();
    // }
  }

  componentDidUpdate() {
    console.log("Navbar componentDidUpdate", this.props);

    document.getElementById("whoami").hidden = true;
    let usr = document.getElementById("user");

    usr.hidden = false;

    usr.dataset.emailId = this.props.user.email;
    usr.dataset.name = this.props.user.name;

    document.getElementById("user-name").innerHTML =
      "Welcome " + this.props.user.given_name;
    document.getElementById("user-pic").src = this.props.user.picture;

    //check if it is varun and then allow
    // if (this.props.user.email === "varunbalendra@gmail.com" ||)
    // document.getElementById("items-add-modify").style.display = "block";
    // document.getElementById("ops-items-holder").hidden = false;
  }

  handleSignOut() {
    this.props.user = null;
    sessionStorage.removeItem("sb-user");
    sessionStorage.removeItem("sb-user-json");
    document.getElementById("whoami").hidden = false;
    document.getElementById("user").hidden = true;
  }

  render() {
    return (
      <div>
        {/* <section className="homeHero">
          <div className="sectionWrapper">
            <div className="homeHero-claimContainer">
              <h1 className="homeHero-claim">You are the light of the world</h1>
              <div style={{ textAlign: "center" }}>
                <br />
                <h3 style={{ fontWeight: "bold" }}>Matthew 5:14</h3>
                <h6 style={{ fontSize: "small" }}>King James Version (KJV)</h6>
              </div>
            </div>
          </div>
        </section> */}
        {/* <div id="light-of-the-world" className="homeHero-claimContainer">
          <h1 className="homeHero-claim">You are the light of the world</h1>
          <div style={{ textAlign: "center" }}>
            <br />
            <h3 style={{ fontWeight: "bold" }}>Matthew 5:14</h3>
            <h6 style={{ fontSize: "small" }}>King James Version (KJV)</h6>
          </div>
        </div> */}

        <nav
          className="navbar navbar-expand-lg bg-body-tertiary"
          style={{ zIndex: "99" }}
        >
          <div className="container-fluid">
            <a className="navbar-brand" href=".">
              <img className="App-logo" src={logo} alt="ST BEDE" />
            </a>

            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarNav"
              style={{ justifyContent: "flex-end" }}
            >
              <ul
                className="navbar-nav align-items-center"
                onClick={(event) =>
                  this.props.showScreen(event.target.innerHTML)
                }
              >
                <li className="nav-item">
                  <NavLink to="/">Start Here</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/sundays">Sundays</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/give">Give</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/auction">Auction</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/about">About</NavLink>
                </li>
                <li className="nav-item">
                  <div id="whoami" className="nav-link"></div>

                  <div
                    id="user"
                    className="nav-link"
                    style={{ color: "blueviolet" }}
                    hidden
                  >
                    <img
                      id="user-pic"
                      style={{
                        width: "25px",
                        marginRight: "10px",
                        borderRadius: "50%",
                      }}
                    />

                    <span id="user-name">SignIn</span>
                    {/* <Escape
                      title="Sign out"
                      onClick={() => {
                        this.handleSignOut();
                      }}
                    /> */}
                  </div>
                </li>
                {/* <li className="nav-item">
                  <NavLink to="/admin">Admin</NavLink>
                </li> */}
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Dropdown link
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default StBNavBar;
