import React, { Component } from "react";

import flogo from "../images/footer-logo.png";
import dlogo from "../images/diocese-logo.png";
import alogo from "../images/anglican-logo.png";

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="footer">
          <div className="sectionWrapper">
            <section className="footer-main">
              <div className="footer-claim">
                {/* <img src={logo} className="App-logo" alt="logo" /> */}
                <h2 className="footer-claim-title">
                  It all starts with a conversation.
                </h2>
              </div>
              <div className="footer-info">
                <div className="footer-info-contact">
                  <div className="footer-block">
                    <p className="footer-heading">Contact Us</p>
                    <a className="link" href="mailto:office@stbedesanglican.ca">
                      <span className="link-text">
                        office@stbedesanglican.ca
                      </span>
                    </a>
                  </div>
                </div>
                <div className="footer-info-headquarters">
                  <div className="footer-block">
                    <p className="footer-heading">Address</p>
                    <a
                      className="link"
                      href="https://maps.app.goo.gl/vjoAVSyb4wojv7QC8"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="link-text">
                        <p>79 Westbourne Av,</p>
                        <p>
                          <span>Scarborough, </span>
                          <br />
                          <span>ON M1L 2Y6</span>
                          <br />
                          <span>Canada</span>
                        </p>
                      </div>
                    </a>
                    <a className="link" href="tel:+34 673 488 662">
                      <p className="link-text">+1 (416) 757-8241</p>
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <section className="footer-nav">
              <div className="footer-brand">
                <div className="footer-brand-menu">
                  <div className="footer-logo" style={{ color: "white" }}>
                    <a
                      className="link is-disabled"
                      href="_blank"
                      rel="noreferrer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.0"
                        width="100.000000pt"
                        height="30.000000pt"
                        viewBox="0 0 200.000000 49.000000"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <g
                          transform="translate(0.000000,49.000000) scale(0.100000,-0.100000)"
                          fill="#000000"
                          stroke="none"
                        >
                          <path d="M122 324 c-18 -12 -22 -24 -20 -52 3 -31 9 -40 43 -59 49 -27 55 -58 10 -57 -16 0 -38 4 -47 8 -26 12 -24 -30 1 -44 53 -28 131 7 131 59 0 30 -18 51 -65 72 -46 22 -39 44 13 43 33 -1 42 3 42 17 0 32 -69 40 -108 13z" />
                          <path d="M250 320 c0 -16 7 -20 35 -20 l35 0 0 -95 c0 -88 1 -95 20 -95 19 0 20 7 20 95 l0 95 35 0 c28 0 35 4 35 20 0 19 -7 20 -90 20 -83 0 -90 -1 -90 -20z" />
                          <path d="M640 225 l0 -115 49 0 c65 0 99 14 111 45 12 32 -3 75 -26 75 -16 0 -16 1 -1 18 43 47 4 92 -80 92 l-53 0 0 -115z m105 50 c0 -15 -8 -21 -32 -23 -30 -3 -33 -1 -33 23 0 24 3 26 33 23 24 -2 32 -8 32 -23z m10 -95 c0 -22 -5 -25 -37 -28 -36 -3 -38 -2 -38 28 0 30 2 31 38 28 32 -3 37 -6 37 -28z" />
                          <path d="M850 335 c-1 -3 -1 -53 -1 -112 l-1 -108 69 0 c54 0 68 3 68 15 0 11 -13 16 -47 18 -46 3 -48 5 -48 33 0 27 2 29 40 29 33 0 40 3 40 20 0 17 -7 20 -40 20 -36 0 -40 3 -40 25 0 23 4 25 45 25 38 0 45 3 45 20 0 18 -7 20 -65 20 -36 0 -65 -2 -65 -5z" />
                          <path d="M1030 226 l0 -116 53 0 c67 0 98 16 120 63 24 47 16 105 -19 138 -19 18 -39 24 -89 27 l-65 4 0 -116z m120 54 c45 -45 13 -130 -50 -130 l-30 0 0 75 0 75 30 0 c17 0 39 -9 50 -20z" />
                          <path d="M1260 335 c-1 -3 -1 -53 -1 -112 l-1 -108 71 -3 c66 -3 71 -1 71 18 0 17 -6 20 -50 20 -49 0 -50 1 -50 30 0 28 2 30 40 30 33 0 40 3 40 20 0 17 -7 20 -40 20 -36 0 -40 3 -40 25 0 23 4 25 45 25 38 0 45 3 45 20 0 18 -7 20 -65 20 -36 0 -65 -2 -65 -5z" />
                          <path d="M454 156 c-10 -26 4 -48 28 -44 17 2 23 10 23 28 0 18 -6 26 -23 28 -13 2 -25 -3 -28 -12z" />
                        </g>
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="footer-brand-social">
                  <p className="footer-heading">Follow Us</p>
                  <ul>
                    <li>
                      <a
                        className="link"
                        href="https://twitter.com/stbedesanglican"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="link-text">Twitter</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="link"
                        href="https://www.facebook.com/stbedesanglican"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="link-text">Facebook</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="link"
                        href="https://instagram.com/churchofstbede"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="link-text">Instagram</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="link"
                        href="https://www.youtube.com/user/stbedesanglican"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="link-text">YouTube</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-brand-legal">
                  <div className="footer-privacy">
                    <a className="link" href="_blank" rel="noreferrer">
                      <span className="link-text">Privacy policy</span>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </footer>

        <div className="wrapper">
          <div id="footer-top">
            <img
              id="footer-logo"
              src={flogo}
              alt="logo"
              className="footerlogo"
            ></img>
          </div>
          <div id="anglican-diocese">
            <a href="https://www.anglican.ca/" target="_blank" rel="noreferrer">
              <img id="anglican-logo" src={alogo} alt="anglican-logo" />
            </a>
            <a
              href="https://www.toronto.anglican.ca/"
              target="_blank"
              rel="noreferrer"
            >
              <img id="diocese-logo" src={dlogo} alt="diocese-logo" />
            </a>
          </div>
          <br />
          <br />
          <p id="copyright">
            <span className="footer-copyright-block">
              &copy; Copyright 2023 St Bede’s Anglican Church
            </span>
            <span className="footer-copyright-block">All Rights Reserved </span>
          </p>

          <div
            id="statusbar"
            style={{
              width: "90%",
              display: "flex",
              justifyContent: "space-between",
              margin: "auto",
              fontSize: "x-small",
              marginTop: "20px",
              color: "white",
            }}
          >
            <div id="pagetime"></div>
            <div id="pagedate"></div>
            <div id="countddown"></div>
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default Footer;
